form {
    padding: 10%;
}

#signup {
    width: 50%;

    .card-footer {
        nav {
            display: flex;
            justify-content: space-around;
        }
    }

    .card-body {
        form {
            div {
                border: 1px solid red;
            }
        }
    }
}