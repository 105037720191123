@import './variables.css';

footer {
    nav {
        .navbar-nav {
            display: flex;
            justify-content: space-around;
            width: 50%;
        }
    }
}

.windows {

}

.window-wrapper {
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: 199;
    cursor: move;
    width: 500px;
    height: 500px;
    padding: 5px;
    background-color: var(--ui-bg-transparent);
    color: var(--ui-fg);
    border: 3px solid var(--ui-fg);
    backdrop-filter: blur(10px);
    padding: 0;
    .card-header {
        display: flex;
        justify-content: space-between;
        background-color: var(--ui-bg);
        .close-button {
            background-color: rgba(0,0,0,0);
            border: none;
            color: var(--ui-fg);
        }
    }
    .window-content {
        padding: 0;
        overflow: scroll;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }

    table {
        list-style-type: none;
        text-align: left;
        background: none;

        tr {
            background: none;
            border: none;

            th {
                background: none;
                color: var(--ui-fg);
            }

            td {
                background: none;
                color: var(--ui-fg);
            }
        }
    }
}

ul.nav-tabs {
    background-color: var(--ui-bg);
    border: 1px solid var(--ui-fg);
    li.nav-item {
        button.nav-link {
            color: var(--ui-fg);

            &.active {
                color: var(--ui-fg);
                border-color: var(--ui-fg);
                background-color: var(--ui-bg-2);
                border-radius: 0;
            }
        }
    }
}

.tab-content {
    .container {
    }
}


svg.chit-icon {
    width: 12px;
    height: 12px;
    .cls-1 {
        fill: var(--ui-fg);
    }
}

.itemPrice {
    display: flex;
    align-items: center;
    justify-content: right;
    font-weight: bold;
}