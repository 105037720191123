#landing {
    padding-top: 20%;

    h1 {
        color: red;
        font-weight: 800;
        margin-bottom: 50px;
        font-size: 40pt;
        font-family: "Helvetica Neue", sans-serif;
        border: none;


    }

    button#race {
        border-radius: 50px;
        border: 0;
        background-color: red;
        padding: 12px 25px 5px;
        color: white;

        h5 {
            font-style: italic;
            font-size: 25px;
            font-family: Helvetica Neue, Helvetica, sans-serif;
            font-weight: 800;
        }
    }

}