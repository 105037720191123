:root {
    --scale-factor: 1;

    --ui-background-transparency: 50%;

    --fw-color: rgba(255, 165, 0, 0.6);
    --st-color: #447366;
    --tr-color: rgba(57, 91, 108, 0.6);

    --fw-hl-color: #ffd500;
    --st-hl-color: rgb(4, 191, 39);
    --tr-hl-color: dodgerblue;
}

.cityMap {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: row nowrap;
    color: var(--ui-fg);
    background-color: var(--ui-bg);
    overflow-y: hidden;
    .cityMapOverlay {
        position: absolute;
        z-index: 10;
        .regions {
            .region-label {
                position: absolute;
            }
        }
    }

    svg {
        text {
            opacity: 0;
        }
    }
}

.react-transform-wrapper {
    width: 100%;
    height: 100%;
}

.city-map-key {
    width: 350px;
    background-color: var(--ui-bg);
    color: var(--ui-fg);
    border: 1px solid var(--ui-fg);
    text-align: left;
    position: fixed;
    top: 50px;
    left: 50px;

    .window-content {
        overflow: hidden;
    }

    .tab-content {
        padding: 5px;
        overflow: scroll;
        height: 300px;
        scrollbar-width: none;
        scroll-behavior: smooth;

        ul {
            padding: 0;
            margin: 0;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 400;
        background-color: var(--ui-bg-2);
        padding: 5px 10px;
    }

    .vstack {

    }

    .card-body {
        padding: 0;
        margin: 0;
    }

    .gigInfo {
        margin: 10px;
        .gigTime {
            &.low {
                color: red;
            }
        }
    }

    ul {
        margin: 0;
        list-style-type: none;
        li:hover {
            background-color: var(--ui-bg-2);
        }
        li:nth-child(2n) {
            background-color: var(--ui-bg-3);
        }
    }

    ul.nav.nav-tabs {
        font-size: 10px;
        list-style-type: none;
        li {
            button {
                font-size: 14px;
                padding: 2px 0;
                &:hover {
                    background-color: var(--ui-bg-2);
                }
            }
        }
    }

    .accordion {
        background-color: var(--ui-bg-3);
        border: none;

        .accordion-item {
            border: none;
            background-color: var(--ui-bg-3);
            .accordion-header {
                button {
                    background-color: var(--ui-bg-2);
                    color: var(--ui-fg);
                }
            }

            .accordion-body {
                padding: 0;

                table {
                    td, th {
                        border: none;
                    }
                }
            }

            .accordion-collapse {
                border: none;
            }
        }
    }
}

.gig-list {
    font-size: 10px;
    white-space: nowrap;

    tr {
        td.gig-type {
            width: 15px;
        }
    }
}

.travelNode {
    position: absolute;
    background: none;
    color: white;
    border-radius: 7px;
    padding: 1px;
    opacity: 75%;
    z-index: 100;
    margin-left: -7pt;
    margin-top: -3.5pt;


    .card-header {
        margin: 0;
        padding: 0;
        display: flex;
        height: 10px;
    }

    .card-text {
        padding: 3px;
    }

    .card-body {
        font-size: 6px;
        text-align: left;
        display: none;
        padding: 0;
    }

    &:not(.location) {
        .modeIcons {
            .tr-dot {
                path {
                }
            }
        }
    }

    &.highlight {
        opacity: 100%;
        .modeIcons {
            .tr-dot {
                stroke: yellow;
                transform-origin: 50% 50%;
                transform: scale(150%);
            }
        }
    }

    &:hover, &.selected {
        opacity: 100%;
        z-index: 200;
        margin-top: -4px;
        margin-left: -10px;
        background: rgba(0,0,0,0.5);

        .modeIcons {
            .tr-dot {
                transform: revert;
                font-size: 8px;
                position: revert;
                path {
                    stroke-width: 25pt;
                    fill-opacity: 100%;
                }
            }
        }

        h1 {
            display: block;
            margin: 0;
            padding-right: 2px;
            margin-left: 2px;
        }

        .actions {
            font-size: 8px;
            margin-top: -1px;
            display: flex;
            align-items: center;

            .dist {
                font-size: 7px;
                margin-left: 2px;
                margin-right: 3px;
            }
        }
    }

    &.selected .card-body {
        display: block;
        min-width: 150px;
    }

    &.here {
        position: absolute;
        margin-top: -5px;
        font-size: 10pt;
        z-index: 199;

        .modeIcons {
            .tr-dot {
                position: absolute;
            }
        }

        .fa-layers.here {
            margin-top: 1px;
            font-size: 7pt;
            .fa-circle {
                path {
                    stroke: #000;
                    stroke-width: 50pt;
                }
            }
            .fa-street-view {
                color: black;
            }
        }
    }

    h1 {
        display: none;
        font-size: 8px;
        font-weight: bold;
        white-space: nowrap;
    }

    .modeIcons {
        font-size: 8px;
        min-width: 10px;

        .tr-fw {
            transform: scale(calc(1 - 0.25 * var(--scale-factor))); z-index: 113;
        }

        .tr-st {
            transform: scale(calc(1 - 0.25 * var(--scale-factor))); z-index: 112;
        }

        .tr-tr {
            transform: scale(calc(1 + 0.1*var(--scale-factor))); z-index: 111;
        }
    }
}

.modeIcons {
    display: flex;
    align-items: center;

    .tr-dot {
        stroke-width: 100pt;
        fill-opacity: 0;
        position: absolute;
    }

    .tr-fw path {
        stroke: var(--fw-hl-color);
        fill: var(--fw-hl-color);
    }

    .tr-st path {
        stroke: seagreen;
        stroke-width: 50pt;
        fill: var(--st-color);
        fill-opacity: 100%;
    }

    .tr-tr path {
        stroke: var(--tr-hl-color);
        fill: var(--tr-hl-color);
    }
}

.city-map-filters.modeIcons {
    display: flex;
    justify-content: space-around;
    .tr-dot {
        position: revert;
        path { fill-opacity: 0; }
        &.selected path {

            fill-opacity: 1;
        }
    }
}




#Routes { /* surface streets */
    g {
        path, line, polyline{
            fill: none;
            stroke: var(--st-color);
        }
        &.active {
            path, line, polyline {
                stroke: var(--st-color);
                stroke-width: 5px;
            }
        }
    }

    line, path, polyline, circle {
        &.highlight {
            stroke: var(--st-hl-color);
            opacity: 100%;
        }
    }
}

#Nodes {
    circle {
        opacity: 0;
    }
    .cls-1 {
        opacity: 0;
    }
}

.connectionLabel {
    /*fill: seagreen;*/
    position: absolute;
    z-index: 150;
    top: 100px;
    left: 100px;
    color: #d5d544;
    font-weight: 700;
    font-family: "Highway Gothic", sans-serif;
    border: 1px solid rgba(1, 188, 1, 0.78);
    padding: 1px;
    border-radius: 12px;
    font-size: 3px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
}

#Locations {
    display: none;
}

#Freeways {
    path, line, polyline {
        stroke: var(--fw-color);
        stroke-width: 5px;
    }

    .highlight {
        stroke: var(--fw-hl-color);
    }
}

#Streets {
    * {
        stroke: var(--st-color);
        stroke-width: 3px;
        opacity: 50%;
    }
}

#Train {
    path, line, polyline {
        stroke: var(--tr-color);
    }
    .highlight {
        stroke: var(--tr-hl-color);
    }
}

#Regions {
    g {
        path {
            fill: rgba(0, 0, 0, 0);
            stroke: var(--ui-fg);

            &:hover {
                stroke: var(--ui-fg);
            }
        }
    }
}

#LAND {
    path {
        fill: var(--ui-bg);
        stroke: #c3b6b6;
        stroke-width: 5px;
    }
}

#Features {
    opacity: 50%;
    path, line, polyline {
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}

.connection {
    stroke-linejoin: round;
}
