:root {

}

#AboutPage {
    h1 {
        font-size: 30px;
        color: red;
    }

    h2 {
        font-size: 20px;
        color: dodgerblue;
    }

    .row {
        margin-top: 5%;
        justify-content: center;

        ul {
            li {
                text-align: left;
            }
        }

        p {
            text-align: justify;
        }
    }
}