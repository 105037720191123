.place-desc {
    font-size: 12px;
    padding: 0;
    margin: 5px;
    text-align: left;
}

table {
    font-size: 14px;
    .itemPrice {
        display: flex;
        align-items: center;
        justify-content: right;
        font-weight: bold;
    }
}

