body {
}

a:link, a:active, a:visited {
  color: red;
  text-decoration: none;
  font-weight: bold;
}

#root {
  text-align: center;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: white;
  nav.navbar {
    display: flex;
    justify-content: space-around;
  }
  .satire {
    background-color: #CC1100;
    color: white;
    width: 100%;
    font-weight: 400;
    font-size: 10px;
    padding: 5px;
    b {
      font-size: 12px;
      font-weight: 900;
    }
  }
}
